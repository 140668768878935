var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.requireLink ? 11 : 12
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.displayName(item)) + " ")];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.displayName(item)) + " ")];
      }
    }])
  }, [_c('v-select', {
    attrs: {
      "label": _vm.label,
      "items": _vm.items,
      "item-text": "data.name",
      "item-value": "uuid",
      "value": _vm.value,
      "rules": [_vm.formRules.required],
      "dense": "",
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('input', $event);
      }
    }
  })], 1), _vm.requireLink ? _c('v-col', {
    staticClass: "align-self-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.routeToLibrary(_vm.route, _vm.value);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-link-variant ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }