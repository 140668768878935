var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": typeof _vm.status.prevStatus != 'undefined',
      "offset-x": !_vm.status.prevStatus,
      "nudge-left": _vm.status.prevStatus ? 50 : 0,
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticStyle: {
            "display": "flex",
            "align-self": "center"
          },
          attrs: {
            "small": "",
            "outlined": "",
            "disabled": _vm.disabled,
            "loading": _vm.loading,
            "color": ("" + (_vm.status.color))
          }
        }, 'v-btn', attrs, false), on), [_vm.status.status !== 'Draft' ? _c('v-badge', {
          attrs: {
            "color": _vm.status.color,
            "icon": "mdi-lock"
          }
        }, [_vm._v(" " + _vm._s(_vm.status.status) + " ")]) : _c('div', {
          staticClass: "text-button"
        }, [_vm._v(" " + _vm._s(_vm.status.status) + " ")])], 1)];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-card', [_c('v-card-actions', {
    staticClass: "pa-0 d-flex flex-row-reverse"
  }, [_vm.status.nextStatus ? _c('v-btn', {
    class: !_vm.status.prevStatus ? 'ma-0 white--text' : 'ma-1 white--text',
    attrs: {
      "small": "",
      "tile": "",
      "elevation": "2",
      "disabled": _vm.disabled,
      "color": ("" + (_vm.status.nextColor))
    },
    on: {
      "click": function ($event) {
        return _vm.promptConfirm(_vm.status.nextStatus);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-arrow-right-thick")]), _vm._v(" " + _vm._s(_vm.status.nextStatus) + " ")], 1) : _vm._e(), _vm.status.prevStatus ? _c('v-btn', {
    staticClass: "ml-1 white--text",
    attrs: {
      "small": "",
      "tile": "",
      "elevation": "2",
      "disabled": _vm.disabled,
      "color": ("" + (_vm.status.prevColor))
    },
    on: {
      "click": function ($event) {
        return _vm.promptConfirm(_vm.status.prevStatus);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.status.prevStatus) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v("mdi-arrow-left-thick")])], 1) : _vm._e()], 1)], 1), _c('confirm-dialog', {
    ref: "confirm"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }