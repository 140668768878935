var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    attrs: {
      "app": "",
      "dark": "",
      "clipped-left": "",
      "color": "primary"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    staticClass: "ml-2 mr-4",
    attrs: {
      "alt": "KTS",
      "src": "https://images.squarespace-cdn.com/content/v1/6081b76972ec8a15b7a63149/adb36b73-531a-4920-bcc5-559630ae8fc8/Logo_medium.svg",
      "max-width": "150",
      "contain": ""
    }
  })], 1), _vm.$store.getters.isSignedIn() ? _c('v-tabs', {
    attrs: {
      "background-color": "primary"
    }
  }, _vm._l(_vm.topLevelRoutes, function (entry, index) {
    return _c('v-tab', {
      key: index,
      attrs: {
        "to": {
          name: entry.route
        }
      }
    }, [_vm._v(" " + _vm._s(entry.label) + " ")]);
  }), 1) : _vm._e(), _c('v-spacer'), _vm.$store.getters.isSignedIn() ? _c('v-menu', {
    attrs: {
      "left": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-account-circle")])], 1)];
      }
    }], null, false, 3569096857)
  }, [_c('v-list', [_c('v-list-item', {
    attrs: {
      "to": "/account"
    }
  }, [_c('v-list-item-title', [_vm._v("Account")])], 1), _c('v-list-item', {
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 1)], 1) : _vm._e()], 1), _c('v-main', {
    attrs: {
      "app": ""
    }
  }, [_c('main-alert'), _vm.$store.state.gettingUser ? [_c('v-progress-circular', {
    staticClass: "d-flex justify-center mt-5",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "indeterminate": "",
      "width": "3",
      "size": "55",
      "color": "primary"
    }
  })] : [_vm.$store.getters.isSignedIn() ? [_c('router-view')] : [_c('sign-in')]]], 2), _c('v-footer', {
    attrs: {
      "absolute": "",
      "app": "",
      "bottom": ""
    }
  }, [_c('span', [_vm._v("© 2022 Keystone Tower Systems. All Rights Reserved.")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }