var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "650"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', [_c('p', [_c('span', [_vm._v(_vm._s(_vm.objectName))]), _c('span', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_c('br'), _vm._v("Changelog with ")]), _c('bordered-text-span', {
    attrs: {
      "text": String(_vm.items.length),
      "font-weight": "bold",
      "padding": "2px"
    }
  }), _vm._v("  Changes ")], 1)]), _c('v-expansion-panels', {
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-expansion-panel', {
      key: item.timestamp
    }, [_c('v-expansion-panel-header', [_c('p', [_vm._v(" " + _vm._s(item.operation) + " by "), _c('span', {
      staticClass: "blue--text text--darken-2"
    }, [_vm._v("@" + _vm._s(item.username))]), _vm._v(" on " + _vm._s(item.timestamp) + " ")])]), _c('v-expansion-panel-content', [_c('ul', _vm._l(item.jsonpatch, function (op) {
      return _c('li', {
        key: item.timestamp + op.value + op.path
      }, [_c('p', [_c('bordered-text-span', {
        attrs: {
          "text": String(op.path),
          "font-weight": "bold",
          "padding": "3px"
        }
      }), op.op === 'remove' ? _c('span', {
        staticClass: "red--text text--lighten-1"
      }, [_vm._v("  Removed ")]) : _vm._e(), op.op === 'replace' ? _c('ul', [op.old_value ? _c('li', [_c('span', {
        staticStyle: {
          "color": "#EF5350",
          "text-decoration": "line-through",
          "text-decoration-thickness": "1px"
        }
      }, [_c('span', {
        staticClass: "red--text text--lighten-1"
      }, [_vm._v(_vm._s(op.old_value))])])]) : _vm._e(), _c('li', [_c('span', {
        staticClass: "green--text text--lighten-1"
      }, [_vm._v(_vm._s(op.value))])])]) : op.op === 'add' ? _c('ul', [_c('li', [_c('span', {
        staticClass: "green--text text--lighten-1"
      }, [_vm._v(_vm._s(op.value))])])]) : _vm._e()], 1)]);
    }), 0)])], 1);
  }), 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.show = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }