var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "dense": "",
      "outlined": "",
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required, _vm.formRules.filename]
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    class: {
      'ml-3': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('model-number-select', {
    ref: "modelNumberRef",
    attrs: {
      "outlined": "",
      "disabled": _vm.disabled,
      "turbine": _vm.value.turbine,
      "value": _vm.value.model_number
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('model_number', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('library-select', {
    attrs: {
      "value": _vm.value.design_basis,
      "label": "Design Basis",
      "route": "design-basis",
      "items": _vm.$store.state.designBases,
      "require-link": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('design_basis', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('library-select', {
    attrs: {
      "value": _vm.value.can_inventory,
      "label": "Can Materials",
      "route": "inventory",
      "items": _vm.$store.state.inventories,
      "require-link": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('can_inventory', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('library-select', {
    attrs: {
      "value": _vm.value.spiral_inventory,
      "label": "Spiral Materials",
      "route": "inventory",
      "items": _vm.$store.state.inventories,
      "require-link": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('spiral_inventory', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('comma-separated-list', {
    attrs: {
      "value": _vm.value.corner_shifts,
      "label": "Corner Shifts",
      "units": "mm",
      "data-lpignore": "true",
      "dense": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('corner_shifts', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Boss Min Distance",
      "units": "mm",
      "placeholder": "67",
      "value": _vm.value.boss_min_dist,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('boss_min_dist', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Boss Target Distance",
      "units": "mm",
      "placeholder": "100",
      "value": _vm.value.boss_target_dist,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('boss_target_dist', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('NumThicknesses', {
    attrs: {
      "value": _vm.value.num_thicknesses,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('num_thicknesses', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('notes', {
    attrs: {
      "value": _vm.value.design_basis_notes,
      "label": "Blueprint Report Notes: 'Tower Design Basis'",
      "notes-info": "Added to pg. 1 of Blueprint under 'Tower Design Basis'. (This overrides Design Basis Blueprint Notes)",
      "col-width": 8,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('design_basis_notes', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.value.certification_intent_notes,
      "label": "Certification Report Notes: 'Design Notes - Notes'",
      "notes-info": "Added to Certification Report pg. 3 'Design Notes' under 'Notes' header. (If left blank 'Note' section will be removed)",
      "col-width": 8,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('certification_intent_notes', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.value.certification_loadset_notes,
      "label": "Certification Report Notes: 'Design Notes - Load Set Notes'",
      "notes-info": "Added to Certification Report pg. 3 'Design Notes' under 'Notes' header. (If left blank defaults will be used)",
      "col-width": 8,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('certification_loadset_notes', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-card', {
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', [_vm._v("Turbine")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 1
    }
  }, [_c('img', {
    staticClass: "turbineImg",
    attrs: {
      "src": require("@/assets/nacelle.png")
    }
  })]), _c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('library-select', {
    attrs: {
      "value": _vm.value.turbine,
      "label": "Turbine",
      "route": "turbine",
      "items": _vm.$store.state.turbines,
      "require-link": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('turbine', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Load Set",
      "items": _vm.sortedLoadSets,
      "item-text": "data.name",
      "item-value": "uuid",
      "value": _vm.value.load_sets,
      "rules": [_vm.formRules.required],
      "multiple": "",
      "chips": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('load_sets', $event, _vm.value);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item,
            attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.disabled
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var _item$data, _item$data2;

              var active = _ref2.active;
              return [_c('v-list-item-action', [_c('v-checkbox', {
                attrs: {
                  "input-value": active
                }
              })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('v-row', {
                attrs: {
                  "no-gutters": "",
                  "align": "center"
                }
              }, [_c('span', [_vm._v(_vm._s(item.data.name))])])], 1), _vm.loadSetUsesTurbine((_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.turbine) ? _c('v-list-item-subtitle', [_c('span', {
                staticClass: "green--text"
              }, [_vm._v("Compatible:")]), _vm._v(" Uses Current Turbine "), _c('bordered-text-span', {
                attrs: {
                  "text": _vm.currentTurbineName,
                  "font-weight": "bold",
                  "padding": "2px"
                }
              })], 1) : (_item$data2 = item.data) !== null && _item$data2 !== void 0 && _item$data2.turbine ? _c('v-list-item-subtitle', [_c('bordered-text-span', {
                attrs: {
                  "text": _vm.getLoadSetTurbineName(item.data.turbine),
                  "font-weight": "bold",
                  "padding": "2px"
                }
              }), _vm._v(" (Incompatible with selected turbine) ")], 1) : _c('v-list-item-subtitle', [_vm._v(" No Turbine Assigned ")])], 1)];
            }
          }], null, true)
        }, 'v-list-item', attrs, false), on))];
      }
    }, {
      key: "selection",
      fn: function (selection) {
        return [_c('v-chip', {
          domProps: {
            "textContent": _vm._s(selection.item.data.name)
          },
          on: {
            "click": function ($event) {
              return _vm.routeToLibrary('load-set', selection.item.uuid);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _vm.value.hub_height ? _c('v-row', [_c('v-col', [_c('v-radio-group', {
    attrs: {
      "label": "Target",
      "value": _vm.value.hub_height.target,
      "mandatory": "",
      "row": "",
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('hub_height.target', $event, _vm.value);
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Hub Height",
      "value": "HUB"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Tip Height",
      "value": "BLADE_TIP"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Max Height",
      "units": "m",
      "value": _vm.value.hub_height.height,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('hub_height.height', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('unit-results', {
    attrs: {
      "results": _vm.turbineResults
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "reverseorder",
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.sections, function (section, sectionIndex) {
    var _vm$model;

    return _c('v-row', {
      key: sectionIndex
    }, [_c('v-col', [_c('section-intent', {
      attrs: {
        "value": section,
        "section-index": sectionIndex,
        "model": (_vm$model = _vm.model) === null || _vm$model === void 0 ? void 0 : _vm$model.sections[sectionIndex],
        "prevent-delete": _vm.value.sections.length <= 1,
        "disabled": _vm.disabled,
        "assemblyDrawingNum": _vm.assemblyDrawingNumber,
        "shellMaterial": _vm.secShellMaterial[sectionIndex] + " or equivalent"
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue(['sections', sectionIndex], $event, _vm.value);
        },
        "delete": function ($event) {
          return _vm.deleteSection(sectionIndex);
        },
        "duplicate": function ($event) {
          return _vm.duplicateSection(sectionIndex);
        }
      }
    })], 1)], 1);
  }), 1)], 1), _c('foundation-intent', {
    attrs: {
      "value": _vm.value.foundation,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('foundation', $event, _vm.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }