var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "align-center",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-col', [_vm._v(" " + _vm._s(_vm.$route.query.report_type) + " "), _c('p', {
    staticClass: "text-subtitle-2 text--disabled"
  }, [_vm._v(_vm._s(_vm.csvFilename))])])], 1), _c('v-col', {
    staticClass: "align-self-end",
    attrs: {
      "cols": "5"
    }
  }, [_vm.items ? _c('v-text-field', {
    attrs: {
      "dense": "",
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm.items === null ? _c('v-progress-linear', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }) : _vm.items === undefined ? _c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" Click button below to download file ")]) : _vm.items.length !== 0 ? _c('v-data-table', {
    staticClass: "elevation-1 px-4",
    attrs: {
      "items": _vm.items,
      "headers": _vm.computedHeaders,
      "search": _vm.search,
      "items-per-page": 5,
      "no-data-text": "No items were retrieved.",
      "no-results-text": "No results were found.",
      "loading": !_vm.items,
      "loading-text": "Loading CSV Data... Please wait",
      "disable-pagination": ""
    }
  }) : _vm.items.length === 0 ? _c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" There are no values to parse in this CSV file. ")]) : _vm._e()], 1), _c('v-btn', {
    staticStyle: {
      "position": "absolute"
    },
    attrs: {
      "color": "primary",
      "dark": "",
      "absolute": "",
      "bottom": "",
      "right": "",
      "fab": "",
      "href": _vm.$route.query.url,
      "target": "_blank"
    }
  }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }