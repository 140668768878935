var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "SN slope",
      "value": _vm.value.sn_slope,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('sn_slope', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Detail category",
      "value": _vm.value.detail_category,
      "units": "MPa",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('detail_category', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('knockdowns', {
    attrs: {
      "value": _vm.value.knockdowns,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('knockdowns', $event, _vm.value);
      }
    }
  })], 1), _c('v-row', [_c('traits', {
    attrs: {
      "value": _vm.value.trait_types,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('trait_types', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }