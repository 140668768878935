var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.reportType === 'SRF_MAP' ? _c('v-col', [_c('comma-separated-list', {
    attrs: {
      "label": "Excluded Sections",
      "value": _vm.value.excluded_sections,
      "clearable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('excluded_sections', $event, _vm.value);
      }
    }
  })], 1) : _vm._e(), ['CERTIFICATION', 'SUMMARY'].includes(_vm.reportType) ? _c('v-col', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Document Number",
      "value": _vm.value.document_number,
      "clearable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('document_number', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('comma-separated-list', {
    attrs: {
      "label": "Excluded Sections",
      "value": _vm.value.excluded_sections,
      "clearable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('excluded_sections', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": "Truncated Report",
      "value": _vm.value.truncated_report
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('truncated_report', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('textarea-list', {
    attrs: {
      "label": "Design Notes",
      "value": _vm.value.design_notes,
      "auto-grow": "",
      "clearable": "",
      "rows": "1"
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('design_notes', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }