var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Consequence of failure safety factor",
      "value": _vm.value.consequence_of_failure_safety_factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('consequence_of_failure_safety_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Material safety factor",
      "value": _vm.value.material_safety_factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('material_safety_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": "Has size effect",
      "input-value": _vm.value.has_size_effect,
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('has_size_effect', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Slope",
      "value": _vm.value.slope,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('slope', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Slope intercept",
      "value": _vm.value.slope_intercept,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('slope_intercept', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Stress concentration type",
      "items": _vm.stressConcentrationTypes,
      "value": _vm.value.stress_concentration_rule.type,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('stress_concentration_rule.type', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Stress concentration factor",
      "value": _vm.value.stress_concentration_rule.factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('stress_concentration_rule.factor', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }