var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', [_vm._v("Foundation")]), _c('v-card-text', [_vm.value ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/foundation.png"),
      "height": "35px",
      "width": "35px"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Height",
      "units": "m",
      "value": _vm.value.height,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Stiffness",
      "units": "GNm/rad",
      "value": _vm.value.stiffness,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('stiffness', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }