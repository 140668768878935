var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Quality class",
      "items": _vm.qualityClass,
      "value": _vm.value.quality_class,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('quality_class', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "label": "Revision",
      "items": _vm.revision,
      "value": _vm.value.revision,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('revision', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Misalignment",
      "value": _vm.value.misalignment,
      "units": "m/m",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('misalignment', $event, _vm.value);
      }
    }
  }), _c('numeric-text-field', {
    attrs: {
      "label": "Safety factor",
      "value": _vm.value.safety_factor,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('safety_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Tower mass partial load factor",
      "value": _vm.value.tower_mass_partial_load_factor,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('tower_mass_partial_load_factor', $event, _vm.value);
      }
    }
  }), _c('numeric-text-field', {
    attrs: {
      "label": "Beta W",
      "value": _vm.value.beta_w,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('beta_w', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('traits', {
    attrs: {
      "value": _vm.value.trait_types,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('trait_types', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mb-2",
    attrs: {
      "elevation": "3"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v(" Set Limits ")]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-checkbox', {
    attrs: {
      "dense": "",
      "input-value": _vm.value.allow_aggressive_squash_limit,
      "label": "Allow aggressive squash limit",
      "disabled": _vm.isGlobal
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('allow_aggressive_squash_limit', $event, _vm.value);
      }
    }
  }), _c('v-spacer'), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "input-value": _vm.value.use_force_parallel_for_shear_interaction,
      "label": "Use force parallel for shear interaction",
      "disabled": _vm.isGlobal
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('use_force_parallel_for_shear_interaction', $event, _vm.value);
      }
    }
  }), _c('v-spacer'), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "input-value": _vm.value.use_hoop,
      "label": "Use hoop",
      "disabled": _vm.isGlobal
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('use_hoop', $event, _vm.value);
      }
    }
  }), _c('v-spacer')], 1)], 1)], 1)], 1), _c('safety-factors', {
    attrs: {
      "value": _vm.value.regional_safety_factors,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('regional_safety_factors', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }