var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "width": "100%",
      "elevation": "3"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_vm._v(" Regional safety factors ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "disabled": _vm.isGlobal
    },
    on: {
      "click": function ($event) {
        return _vm.addSafetyFactor();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" Add SRF ")], 1)], 1), _c('v-card-text', _vm._l(_vm.value, function (sFactor, idx) {
    return _c('v-card', {
      key: idx,
      staticClass: "safetyfactors",
      attrs: {
        "width": "100%"
      }
    }, [_c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
      attrs: {
        "value": sFactor.name,
        "label": "Name",
        "rules": [_vm.formRules.required],
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'name'], $event, _vm.value);
        }
      }
    })], 1), _c('v-col', [_c('v-select', {
      attrs: {
        "label": "Regional safety factor",
        "items": _vm.safetyFactorSchemas.map(function (s) {
          return s.type;
        }),
        "value": sFactor.type,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateType(idx, $event);
        }
      }
    })], 1), _c('v-btn', {
      attrs: {
        "disabled": _vm.isGlobal,
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteSafetyFactor(idx);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-row', {
      attrs: {
        "dense": ""
      }
    }, [['ConstSafetyFactor', 'RampingSafetyFactor'].includes(sFactor.type) ? _c('v-col', [_c('numeric-text-field', {
      attrs: {
        "label": "Factor",
        "value": sFactor.factor,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'factor'], $event, _vm.value);
        }
      }
    })], 1) : _vm._e(), sFactor.type === 'RampingSafetyFactor' ? [_c('v-col', [_c('numeric-text-field', {
      attrs: {
        "label": "Low",
        "units": "m",
        "value": sFactor.low,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'low'], $event, _vm.value);
        }
      }
    })], 1), _c('v-col', [_c('numeric-text-field', {
      attrs: {
        "label": "High",
        "units": "m",
        "value": sFactor.high,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'high'], $event, _vm.value);
        }
      }
    })], 1), _c('v-col', [_c('numeric-text-field', {
      attrs: {
        "label": "Baseline",
        "value": sFactor.baseline,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'baseline'], $event, _vm.value);
        }
      }
    })], 1)] : _vm._e()], 2), sFactor.type === 'ElevationMapSafetyFactor' ? [_c('v-row', [_c('numeric-text-field', {
      attrs: {
        "label": "Max delta",
        "value": sFactor.max_delta,
        "units": "m",
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'max_delta'], $event, _vm.value);
        }
      }
    })], 1), _c('v-row', [_c('elevation-mapping', {
      attrs: {
        "value": sFactor.safety_factor_map,
        "disabled": _vm.isGlobal
      },
      on: {
        "input": function ($event) {
          return _vm.updateValue([idx, 'safety_factor_map'], $event, _vm.value);
        }
      }
    })], 1)] : _vm._e()], 2)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }