var render = function () {
  var _vm$value$nut, _vm$value$nut2, _vm$value$nut3, _vm$value$nut4, _vm$value$washer, _vm$value$washer2, _vm$value$washer3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Bolt ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Head Height",
      "value": _vm.value.head_height,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('head_height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Hole Diameter",
      "value": _vm.value.hole_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('hole_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Major Diameter",
      "value": _vm.value.major_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('major_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Pitch",
      "value": _vm.value.pitch,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('pitch', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Hex Nut ")]), _c('v-card-text', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Max Diameter",
      "value": (_vm$value$nut = _vm.value.nut) === null || _vm$value$nut === void 0 ? void 0 : _vm$value$nut.max_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('nut.max_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Min Diameter",
      "value": (_vm$value$nut2 = _vm.value.nut) === null || _vm$value$nut2 === void 0 ? void 0 : _vm$value$nut2.min_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('nut.min_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Height",
      "value": (_vm$value$nut3 = _vm.value.nut) === null || _vm$value$nut3 === void 0 ? void 0 : _vm$value$nut3.height,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('nut.height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Socket Outer Diameter",
      "value": (_vm$value$nut4 = _vm.value.nut) === null || _vm$value$nut4 === void 0 ? void 0 : _vm$value$nut4.socket_outer_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('nut.socket_outer_diameter', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Washer ")]), _c('v-card-text', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Inner Diameter",
      "value": (_vm$value$washer = _vm.value.washer) === null || _vm$value$washer === void 0 ? void 0 : _vm$value$washer.inner_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('washer.inner_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Outer Diameter",
      "value": (_vm$value$washer2 = _vm.value.washer) === null || _vm$value$washer2 === void 0 ? void 0 : _vm$value$washer2.outer_diameter,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('washer.outer_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Thickness",
      "value": (_vm$value$washer3 = _vm.value.washer) === null || _vm$value$washer3 === void 0 ? void 0 : _vm$value$washer3.thickness,
      "units": "mm",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('washer.thickness', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1)], 1), _c('material', {
    attrs: {
      "value": _vm.value.grade,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('grade', $event, _vm.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }