var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-select', {
    attrs: {
      "label": "SCF rule",
      "items": _vm.scfRuleSchemas.map(function (schema) {
        return schema.type;
      }),
      "value": _vm.value.type,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function ($event) {
        return _vm.updateType($event);
      }
    }
  })], 1), _vm.value.type === 'ConstSCFRule' ? _c('v-row', [_c('numeric-text-field', {
    attrs: {
      "label": "Factor",
      "value": _vm.value.factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('factor', $event, _vm.value);
      }
    }
  })], 1) : _vm._e(), _vm.value.type === 'TaperDeltaSCFRule' ? _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Constant factor",
      "value": _vm.value.const_factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('const_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Delta factor",
      "value": _vm.value.delta_factor,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('const_factor', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), ['MinThicknessSCFRule', 'NearestThicknessSCFRule'].includes(_vm.value.type) ? _c('v-row', [_c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.addThickness();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.scfHeaders,
      "items": _vm.value.thickness_mapping,
      "disable-pagination": "",
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('tr', [_c('td', [_c('numeric-text-field', {
          attrs: {
            "units": "mm",
            "value": item.thickness,
            "required": "",
            "disabled": _vm.disabled
          },
          on: {
            "input": function ($event) {
              return _vm.updateValue(['thickness_mapping', index, 'thickness'], $event, _vm.value);
            }
          }
        })], 1), _c('td', [_c('numeric-text-field', {
          attrs: {
            "value": item.scf,
            "required": "",
            "disabled": _vm.disabled
          },
          on: {
            "input": function ($event) {
              return _vm.updateValue(['thickness_mapping', index, 'scf'], $event, _vm.value);
            }
          }
        })], 1), _c('td', [_c('v-icon', {
          attrs: {
            "small": "",
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              return _vm.deleteThickness(index);
            }
          }
        }, [_vm._v(" mdi-delete ")])], 1)])];
      }
    }], null, false, 1810523113)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }